import React, { useState } from "react";
import { Link } from "react-router-dom";
import AddSelfModal from "./AddSelfModal";
import { useTranslation } from "react-i18next";
import {
  useAllSelfQuery,
  useDeleteSelfMutation,
} from "../../redux/api/self/selfApi";
import { useSelector } from "react-redux";
import DeleteConfirmationModal from "../deleteConfirmationModal/DeleteConfirmationModal";
import toast from "react-hot-toast";

const Self = () => {
  const store = useSelector((state) => state.store);
  const storeId = store.storeId;
  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const [mode, setMode] = useState("add");
  const [id, setId] = useState();
  const [self, setSelf] = useState();
  const { data } = useAllSelfQuery({ storeId: store.storeId });
  const [deleteSelf] = useDeleteSelfMutation();
  console.log(data?.data);
  const openModal = () => {
    setMode("add");
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleDelete = async (id) => {
    setIsAddingLoading(true);
    const res = await deleteSelf({ id, storeId });
    if (res?.data?.success === true) {
      toast.success(res?.data?.message);
    }
    setIsAddingLoading(false);
    setIsDeleteConfirmationModalOpen(false);
  };
  const closeDeleteConfirmationModal = () => {
    setIsDeleteConfirmationModalOpen(false);
  };
  return (
    <div>
      <div className="d-flex justify-content-end">
        <button
          style={{ width: "170px", height: "35px" }}
          className="btn btn-outline-theme"
          onClick={openModal}
        >
          <i className="fa fa-plus-circle me-1"></i>
          {t("Add Shelf")}
        </button>
      </div>

      <div className="table-responsive my-3">
        <table className="table table-hover text-nowrap">
          <thead>
            <tr>
              <th className="border-top-0 pt-0 pb-2">{t("Serial")}</th>

              <th className="border-top-0 pt-0 pb-2">{t("Name")}</th>
              <th className="border-top-0 pt-0 pb-2">{t("Actions")} </th>
            </tr>
          </thead>
          <tbody>
            {data?.data.map((self, i) => (
              <tr>
                <td className="align-middle">
                  <Link>1{i + 1}</Link>
                </td>

                <td className="align-middle">{self?.name}</td>

                <td className="align-middle">
                  <span
                    className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center cursor-pointer "
                    data-bs-toggle="dropdown"
                  >
                    <i className="bi bi-gear fs-9px fa-fw me-5px"></i> E/D
                  </span>
                  <div className="dropdown-menu">
                    <Link
                      onClick={() => {
                        setSelf(self);
                        setMode("edit");
                        setIsModalOpen(true);
                      }}
                      className="dropdown-item"
                    >
                      <i
                        className="bi bi-pencil-square"
                        style={{ marginRight: "5px" }}
                      ></i>
                      {t("Edit")}
                    </Link>

                    <button
                      onClick={() => {
                        setId(self.id);
                        setIsDeleteConfirmationModalOpen(true);
                      }}
                      className="dropdown-item"
                    >
                      <i
                        className="bi bi-trash pr-2"
                        style={{ marginRight: "5px" }}
                      ></i>
                      {t("Delete")}
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isModalOpen && (
        <AddSelfModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          mode={mode}
          self={self}
        />
      )}
      {isDeleteConfirmationModalOpen && (
        <DeleteConfirmationModal
          isAddingLoading={isAddingLoading}
          closeModal={closeDeleteConfirmationModal}
          confirmDelete={() => handleDelete(id)}
        />
      )}
    </div>
  );
};

export default Self;
