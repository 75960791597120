import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useSingleCustomerGetQuery } from "../../redux/api/customer/customerApi";
import { useSalesCreateMutation } from "../../redux/api/pos/posApi";
import { useAllTransactionTypeApiGetQuery } from "../../redux/api/transactionType/transactionTypeApi";
import ReactSelect from "../helpers/ReactSelect";
import { useSelector } from "react-redux";

const SellesModal = ({
  paymentModalVisible,
  handleClosePaymentModal,
  grandTotal,
  customerId,
  setCustomerId,
  salesDate,
  orderData,
  purchaseCost,
  setOrderData,
  tax
}) => {
  const store = useSelector((state) => state.store);
  const [transactionTypeId, setTransactionTypeId] = useState(null);
  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const { data: transactionTypeIdData } = useAllTransactionTypeApiGetQuery({
    storeId: store.storeId,
  });
  // console.log(transactionTypeIdData);
  const navigate = useNavigate();
  const { data } = useSingleCustomerGetQuery(
    { id:customerId, params: { storeId: store.storeId } },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const balance = data?.data?.walletBalance;

  const [afterDiscount, setAfterDiscount] = useState(grandTotal);
  const [discount, setDiscount] = useState(0);
  const [paid, setPaid] = useState(0);

  const initialTransactionTypeIdData = transactionTypeIdData?.data;
  const transactionTypeIdOptions = initialTransactionTypeIdData?.map(
    (item) => ({
      value: item?.id,
      label: item?.name,
    })
  );

  const handleTransactionChange = (values) => {
    setTransactionTypeId(values?.value);
  };
  const options4 = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];
  const initialValues = {
    note: "",
    paid: 0,
    discount: 0,
    isWallet: false,
  };

  const validationSchema = Yup.object().shape({
    note: Yup.string(),
    paid: Yup.number().typeError("Must be a number"),
    discount: Yup.number()
      .typeError("Must be a number")
      .min(0, "Discount cannot be negative")
      .max(100, "Discount cannot be greater than 100"),

    isWallet: Yup.boolean().required("Please select wallet"),
  });

  const [salesCreate] = useSalesCreateMutation();

  const updateValuesOnDiscountChange = (discount) => {
    const discountedAmount = (discount / 100) * grandTotal;
    setAfterDiscount(grandTotal - discountedAmount);
  };

  const updateValuesOnPaidChange = (paid) => {
    const updatedBalance = balance - paid;
    setPaid(paid);
  };

  const onSubmit = async (values, { resetForm }) => {
    setIsAddingLoading(true);
    if (!customerId) {
      toast.error("Select Customer");
      setIsAddingLoading(false);
      return;
    }
    if (orderData?.length === 0) {
      toast.error("Add some products in cart");
      setIsAddingLoading(false);
      return;
    }
    const finalProducts = [...orderData].map((order) => {
      let subunit = order?.subQuantity;
      const newQty = order?.unit?.relatedByValue
        ? Number(order?.quantity) * Number(order?.unit?.relatedByValue)
        : Number(order?.quantity);
      return {
        ...order,
        quantity: Number(order?.quantity),
        subQuantity: Number(order?.subQuantity),
        qty: newQty + Number(subunit),
      };
    });
    const data = {
      grandTotal,
      purchaseCost,
      customerId,
      salesDate,
      note: values.note,
      paid: values.paid,
      discount: values.discount,
      transactionTypeId,
      products: finalProducts,
      isWallet: values.isWallet,
      storeId: store.storeId,
      tax
    };

    if (afterDiscount < values.paid) {
      toast.error("Paid amount must be less than or equal to after discount");
      setIsAddingLoading(false);
    } else {
      const res = await salesCreate(data);

      if (res?.data) {
        toast.success("Sales Successful");
        resetForm();
        setOrderData([]);
        setCustomerId("");
        setIsAddingLoading(false);
        handleClosePaymentModal();
        window.open(
          `${process.env.REACT_APP_BASE_URL}/invoice/${res?.data?.data.id}`,
          "_blank"
        );
        navigate("/customer-order");
      } else {
        toast.error("Error creating sales");
      }
    }
    setIsAddingLoading(false);
  };

  return (
    <Modal show={paymentModalVisible} onHide={handleClosePaymentModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ values, handleChange }) => (
            <Form>
              <table className="table table-bordered text-left">
                <tbody>
                  <tr>
                    <td width="50%">
                      <strong className="float-left">Paying Items: </strong>
                      <strong className="float-right">
                        (<span id="items">{orderData?.length}</span>)
                      </strong>
                    </td>
                    <td>
                      <strong className="float-left">Total Receivable: </strong>
                      <strong className="float-right">
                        (<span id="receivable">{grandTotal?.toFixed(2)}</span>{" "}
                        Tk)
                      </strong>
                      <input
                        type="hidden"
                        name="receivable_amount"
                        id="receivable_input"
                        value="152.83"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td width="50%">
                      <strong className="float-left">After Discount : </strong>
                      <strong className="float-right">
                        (
                        <span id="after_discount">
                          {afterDiscount?.toFixed(2)}
                        </span>{" "}
                        Tk)
                      </strong>
                    </td>
                    <td>
                      <strong className="float-left">Balance </strong>
                      <strong className="float-right">
                        (<span id="balance">{balance ?? 0}</span> Tk)
                      </strong>
                      <input
                        type="hidden"
                        name="balance_input"
                        value={balance ?? 0}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className=" d-flex align-items-center gap-2">
                <div className="form-group col-md-6 ">
                  <label htmlFor="transactionTypeId">
                    Transaction Account
                    <span className="field_required"></span>
                  </label>
                  <ReactSelect
                    placeHolder="Select"
                    options={transactionTypeIdOptions}
                    handleChange={handleTransactionChange}
                  />
                </div>

                <div className="form-group col-lg-6 px-2">
                  <label htmlFor="isWallet">Wallet/Direct Transaction</label>
                  <Field className="form-control" name="isWallet">
                    {(props) => {
                      const handleChange = (option) => {
                        props.form.setFieldValue("isWallet", option.value);
                      };
                      return (
                        <ReactSelect
                          placeHolder="Select "
                          options={options4}
                          handleChange={handleChange}
                        />
                      );
                    }}
                  </Field>
                </div>
              </div>
              <div className="d-flex my-2 gap-2">
                <div className="form-group col-md-6">
                  <label htmlFor="discount">Discount(%)</label>
                  <Field
                    type="number"
                    className="form-control"
                    id="discount"
                    name="discount"
                    placeholder="0%"
                    onChange={(e) => {
                      handleChange(e);
                      setDiscount(e.target.value);
                      updateValuesOnDiscountChange(e.target.value);
                    }}
                  />
                  <ErrorMessage
                    name="discount"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="paid">Pay Amount</label>
                  <Field
                    name="paid"
                    type="number"
                    step="any"
                    className="form-control"
                    placeholder="Pay Amount..."
                    onChange={(e) => {
                      handleChange(e);
                      updateValuesOnPaidChange(e.target.value);
                    }}
                  />
                  <ErrorMessage
                    name="paid"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="form-group col-md-12 mb-3">
                <label htmlFor="note">Note</label>
                <Field
                  name="note"
                  as="textarea"
                  className="form-control"
                  placeholder="Enter Note (Optional)"
                />
                <ErrorMessage
                  name="note"
                  component="div"
                  className="text-danger"
                />
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => handleClosePaymentModal()}
                  className="btn btn-outline-default"
                  data-bs-dismiss="modal"
                  disabled={isAddingLoading}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-info "
                  disabled={isAddingLoading}
                >
                  {isAddingLoading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    " Payment"
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default SellesModal;
