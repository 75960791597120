import React, { useEffect, useState } from "react";
import CustomerModal from "../customer/CustomerModal";
import SellesModal from "./SellesModal";
import PerfectScrollbar from "react-perfect-scrollbar";
import Currency from "../../Shared/Currency";
import { useTranslation } from "react-i18next";

const OrderSidbar = ({
  handlePayment,
  orderData,
  setOrderData,
  deductQty,
  addQty,
  toggleConfirmation,
  removeOrder,
  getSubTotalPrice,
  isModalOpen,
  closeModal,
  handleClosePaymentModal,
  paymentModalVisible,
  getTotalPrice,
  getTaxesPrice,
  customerId,
  setCustomerId,
  salesDate,
  deductSubQty,
  addSubQty,
}) => {
  const [salePriceInput, setSalePriceInput] = useState({});
  const [subTotalPrice, setSubTotalPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [purchaseCost, setPurchaseCost] = useState(0);
  const [tax, setTax] = useState(0);

  // Update subtotal and total prices whenever salePriceInput changes
  useEffect(() => {
    const newSubTotalPrice = orderData?.reduce((acc, order) => {
      const salePrice = salePriceInput[order?.id] || order?.salePrice;
      let subunit = order?.subUnits ? order?.subQuantity : 0;
      if (order?.subUnits) {
        subunit = Number(subunit) / Number(order?.unit?.relatedByValue);
      }
      return acc + salePrice * (Number(order?.quantity) + subunit);
    }, 0);
    const newPurchaseCost = orderData?.reduce((acc, order) => {
      const purchaseCost = salePriceInput[order?.id] || order?.purchaseCost;
      let subunit = order?.subUnits ? order?.subQuantity : 0;
      if (order?.subUnits) {
        subunit = Number(subunit) / Number(order?.unit?.relatedByValue);
      }
      return acc + purchaseCost * (Number(order?.quantity) + subunit);
    }, 0);

    const calculateTax = (tax / 100) * newSubTotalPrice;
    const newTotalPrice = newSubTotalPrice + calculateTax;

    setSubTotalPrice(newSubTotalPrice);
    setTotalPrice(newTotalPrice);
    setPurchaseCost(newPurchaseCost);
  }, [salePriceInput, orderData, tax]);

  const handleSalePriceChange = (event, orderId) => {
    const newSalePrice = parseFloat(event.target.value);

    if (!isNaN(newSalePrice)) {
      setSalePriceInput((prevInput) => ({
        ...prevInput,
        [orderId]: newSalePrice,
      }));
    }
  };

  const handleGetTotalPrice = (order) => {
    const price = salePriceInput[order?.id] || order?.salePrice;
    const unit = parseInt(order?.quantity);
    let subunit = order?.subUnits ? parseInt(order?.subQuantity) : 0;
    if (order?.subUnits) {
      if (order.unit.operator === "*") {
        subunit = subunit / Number(order?.unit?.relatedByValue);
      }
      if (order.unit.operator === "/") {
        subunit = subunit * Number(order?.unit?.relatedByValue);
      }
    }
    return price * (unit + subunit);
  };
  const handleTax = (e) => {
    console.log(e.target.value);
    setTax(e.target.value);
  };
  const { t } = useTranslation();
  return (
    <div>
      <PerfectScrollbar className="pos-sidebar-body tab-content h-100">
        <div className="tab-pane fade h-100 show active" id="newOrderTab">
          {orderData && orderData?.length > 0 ? (
            orderData?.map((order, index) => (
              <div className="pos-order" key={index}>
                <div className="pos-order-product">
                  <div
                    className="img"
                    style={{
                      backgroundImage: order?.image
                        ? "url(" + order?.image + ")"
                        : "url(https://i.ibb.co/KjdtK7T/download.png)",
                    }}
                  ></div>

                  <div className="flex-1">
                    <div className="h6 mb-1">{order?.productName}</div>
                    <div className="d-flex align-items-center">
                      {Currency}
                      <div className="small">
                        <input
                          type="number"
                          className="form-control w-100px form-control-sm mx-2 bg-white bg-opacity-25 bg-white bg-opacity-25 text-center"
                          value={salePriceInput[order?.id] || order?.salePrice}
                          onChange={(event) =>
                            handleSalePriceChange(event, order?.id)
                          }
                        />
                      </div>
                    </div>
                    <div className="small mb-2">
                      {order?.options &&
                        order?.options.map((option, index) => (
                          <div key={index}>
                            - {option.key}: {option?.value}
                          </div>
                        ))}
                    </div>
                    <div>
                      <div className="d-flex flex-col gap-2">
                        <label htmlFor="">{order?.unit?.name}</label>
                        <div className="d-flex mb-3">
                          <input
                            type="number"
                            className="form-control w-full form-control-sm mx-2 bg-white bg-opacity-25 bg-white bg-opacity-25 text-center"
                            value={order?.quantity}
                            // readOnly
                            onChange={(event) => addQty(event, order?.id)}
                          />
                        </div>
                      </div>
                      {order?.subUnits && (
                        <div className="d-flex flex-col gap-2">
                          <label htmlFor="" className="pr-4">
                            {order?.subUnits?.name}
                          </label>
                          <div className="d-flex">
                            <input
                              type="number"
                              className="form-control w-full form-control-sm mx-2 bg-white bg-opacity-25 bg-white bg-opacity-25 text-center"
                              value={order?.subQuantity}
                              // readOnly
                              onChange={(event) => addSubQty(event, order?.id)}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="pos-order-price d-flex flex-column">
                  <div>
                    {Currency} {handleGetTotalPrice(order)?.toFixed(2)}
                  </div>
                  <div className="text-end mt-auto">
                    <button
                      onClick={(event) =>
                        toggleConfirmation(event, order?.id, true)
                      }
                      className="btn btn-sm btn-outline-gray-500"
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  </div>
                </div>
                {order?.confirmation && (
                  <div className="pos-order-confirmation text-center d-flex flex-column justify-content-center">
                    <div className="mb-1">
                      <i className="bi bi-trash fs-36px lh-1"></i>
                    </div>
                    <div className="mb-2">Remove this item?</div>
                    <div>
                      <button
                        onClick={(event) =>
                          toggleConfirmation(event, order?.id, false)
                        }
                        className="btn btn-outline-white btn-sm ms-auto me-2 width-100px"
                      >
                        No
                      </button>
                      <button
                        onClick={(event) => removeOrder(event, order?.id)}
                        className="btn btn-outline-theme btn-sm width-100px"
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="h-100 d-flex align-items-center justify-content-center text-center p-20">
              <div>
                <div className="mb-3 mt-n5">
                  <i
                    className="bi bi-bag text-white text-opacity-50"
                    style={{ fontSize: "6em" }}
                  ></i>
                </div>
                <h5>No order found</h5>
              </div>
            </div>
          )}
        </div>

        <div className="tab-pane fade h-100" id="orderHistoryTab">
          <div className="h-100 d-flex align-items-center justify-content-center text-center p-20">
            <div>
              <div className="mb-3 mt-n5">
                <svg
                  width="6em"
                  height="6em"
                  viewBox="0 0 16 16"
                  className="text-gray-300"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M14 5H2v9a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V5zM1 4v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4H1z"
                  />
                  <path d="M8 1.5A2.5 2.5 0 0 0 5.5 4h-1a3.5 3.5 0 1 1 7 0h-1A2.5 2.5 0 0 0 8 1.5z" />
                </svg>
              </div>
              <h5>No order history found</h5>
            </div>
          </div>
        </div>
      </PerfectScrollbar>

      <div className="pos-sidebar-footer">
        <div className="d-flex align-items-center mb-2">
          <div>{t("Subtotal")}</div>

          <div className="flex-1 text-end h6 mb-0">
            {Currency} {subTotalPrice?.toFixed(2)}
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div>
            <div className="d-flex mb-3">
              {t("Tax")}
              <input
                type="number"
                className="form-control w-25 form-control-sm mx-2 bg-white bg-opacity-25 bg-white bg-opacity-25 text-center align-items-center"
                value={tax}
                // readOnly
                onChange={(e) => handleTax(e)}
              />
              %
            </div>
          </div>
          <div className="flex-1 text-end h6 mb-0">
            {Currency} {(tax / 100) * subTotalPrice}
          </div>
        </div>
        <hr />
        <div className="d-flex align-items-center mb-2">
          <div>{t("total")}</div>

          <div className="flex-1 text-end h4 mb-0">
            {Currency} {totalPrice?.toFixed(2)}
          </div>
        </div>
        <div className="mt-3">
          {isModalOpen && (
            <CustomerModal isModalOpen={isModalOpen} closeModal={closeModal} />
          )}
          {paymentModalVisible && (
            <SellesModal
              tax={(tax / 100) * subTotalPrice}
              paymentModalVisible={paymentModalVisible}
              grandTotal={totalPrice}
              purchaseCost={purchaseCost}
              customerId={customerId}
              setCustomerId={setCustomerId}
              salesDate={salesDate}
              handleClosePaymentModal={handleClosePaymentModal}
              orderData={orderData}
              setOrderData={setOrderData}
            />
          )}
          <div className="btn-group d-flex">
            <button
              onClick={handlePayment}
              className="btn btn-outline-theme rounded-0 w-150px"
            >
              <i className="bi bi-send-check fa-lg"></i>
              <br />
              <span className="small">Submit Order</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSidbar;
